/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Companies = lazy(() => import("./Companies"));

const CompaniesComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Companies {...props} />
        </Suspense>
    );
};

export default CompaniesComponent;