/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const FuelCardsSingle = lazy(() => import("./FuelCardsSingle"));

const FuelCardsSingleComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <FuelCardsSingle {...props} />
        </Suspense>
    );
};

export default FuelCardsSingleComponent;