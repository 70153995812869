/**
 * external libs
 */
import React, { createContext, PropsWithChildren, useState, useEffect, useContext, useCallback } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
/**
 * components
 */
import Menu from './Menu'
import Carts from './Carts'
import avatar from './../../assets/icons/avatar.svg'
import logout from './../../assets/icons/log-out.svg'
import StanchionChooser from './../../pages/map/StanchionChooser'
import StanchionInfo from './../../pages/map/StanchionInfo'
import MapList from './../../pages/map/MapList'
import MapContext from './../../pages/map/MapContext'
import Button from './../../common-components/button/Button'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * hooks
 */
import { usePrivateLayoutData } from './hooks'
/**
 * utils
 */
import useSender from './../../utils/sender'
import { useAllData } from './../../utils/all-data'
/**
 * types
 */
import {
    GlobalContextType,
    MenuContextType,
    RolesType,
    RolesTranslateType,
    CardType,
    PetrolType,
    TariffType,
} from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'

export const MenuContext = createContext<MenuContextType>({
    openChat: false,
    setOpenChat: () => {},
    openDrivers: false,
    setOpenDrivers: () => {},
    openFuelCards: false,
    setOpenFuelCards: () => {},
})

const PrivateLayout: React.FC<PropsWithChildren> = () => {
    const navigate = useNavigate()

    const { user, changeUser, cards, setCards, addAlert, setPushCount, setPetrol } =
        useContext<GlobalContextType>(GlobalContext)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const { routeData, isMap } = usePrivateLayoutData()
    const [access, setAccess] = useState(false)
    const { logIn, logOut, http } = useSender()
    const { getAllData } = useAllData()
    const [openChat, setOpenChat] = useState(false)
    const [openDrivers, setOpenDrivers] = useState(false)
    const [openFuelCards, setOpenFuelCards] = useState(false)

    const firstVisitHandler = useCallback(() => {
        if (user?.uID && user?.Role) {
            setAccess(true)
        } else {
            const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
            const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
            // console.log(accessToken)
            // console.log(sessionStorage.getItem('accessToken'))
            // console.log(localStorage.getItem('accessToken'))
            // console.log(refreshToken)
            // console.log(sessionStorage.getItem('refreshToken'))
            // console.log(localStorage.getItem('refreshToken'))
            const uIDLocal = localStorage.getItem('uid')
            const roleLocal = localStorage.getItem('urole')
            const unameLocal = localStorage.getItem('uname')
            const companyidLocal = localStorage.getItem('companyid')

            if (accessToken && refreshToken && uIDLocal && roleLocal && changeUser) {
                logIn(accessToken, refreshToken, uIDLocal, roleLocal, unameLocal || '', companyidLocal || '')
                let user: any = {
                    Role: roleLocal as keyof typeof RolesType,
                    uID: uIDLocal,
                    uName: unameLocal,
                }
                if (companyidLocal) user.companyid = companyidLocal
                changeUser(user)

                setAccess(true)
            } else {
                logOut()
            }
        }
    }, [user, setAccess])

    useEffect(() => {
        firstVisitHandler()
    }, [firstVisitHandler])

    useEffect(() => {
        const getCards = async () => {
            console.log(user?.Role)
            if (user?.Role === RolesType.operator || user?.Role === RolesType.managers) {
                navigate(`/manage`)
            }
            if (user?.Role && user?.uID && user?.Role !== RolesType.operator && user?.Role !== RolesType.managers) {
                try {
                    const responses = await Promise.all([
                        user.Role === RolesType.companies
                            ? http.get(`/${user.Role}/${user.uID}/cards`)
                            : getAllData(`/${user.Role}/${user.uID}/cards`),
                        http.get('/notifications/sentCount'),
                        http.get('/tariffs'),
                    ])

                    console.log(responses[2])

                    if (setPetrol) {
                        setPetrol(responses[2]?.data?.data || [])
                    }
                    if (setCards) {
                        setCards(responses[0] || [])
                    }

                    if (setPushCount) {
                        setPushCount((responses[1].data as { count: number }).count || 0)
                    }
                } catch (e: any) {
                    if (addAlert) {
                        addAlert({
                            text: `Ошибка загрузки данных личного кабинета: ${e?.code} | ${e?.status} | ${e?.config?.url}`,
                            type: 'error',
                        })
                    }
                }
            }
        }

        getCards()
    }, [setCards, user, setPushCount, setPetrol])

    if (!access || !cards) {
        return null
    }

    return (
        <>
            <Helmet>
                <title>{routeData?.title ?? ''}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <MenuContext.Provider
                value={{ openChat, setOpenChat, openDrivers, setOpenDrivers, openFuelCards, setOpenFuelCards }}>
                <div className="container">
                    <div className={`${styles.officeLayout} ${isMap ? styles.officeLayout_map : ''}`}>
                        <div className={styles.officeLayout__drawerWrapper}>
                            {!!isMap ? (
                                <MapContext>
                                    <Button
                                        handler={() => navigate('/office')}
                                        label="Вернуться на главную"
                                        type="button"
                                        subClasses={styles.officeLayout__toMainButton}
                                    />

                                    <div
                                        className={`${styles.officeLayout__drawer} ${styles.officeLayout__drawer_input}`}>
                                        <StanchionChooser />
                                    </div>

                                    <StanchionInfo />

                                    <MapList />

                                    <Outlet />
                                </MapContext>
                            ) : (
                                <div
                                    className={`${styles.officeLayout__drawer} ${drawerOpen ? styles.officeLayout__drawer_open : ''}`}>
                                    <div className={styles.officeLayout__drawerScroll}>
                                        <div className={styles.officeLayout__opener}>
                                            <button
                                                type="button"
                                                className={styles.officeLayout__openButton}
                                                onClick={() => setDrawerOpen((pre) => !pre)}>
                                                <svg
                                                    className={styles.officeLayout__openIco}
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.6001 16.8L14.4001 12L9.6001 7.20005"
                                                        strokeWidth="1.8"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>

                                        <Menu />
                                        <Carts />

                                        <div className={styles.user}>
                                            <div className={styles.user__logoWrap}>
                                                <img src={avatar} alt="avatar" />
                                            </div>

                                            <div className={styles.user__info}>
                                                <p className={styles.user__label}>{user?.uName || ''}</p>
                                                {!!user?.Role && (
                                                    <p className={styles.user__name}>
                                                        {RolesTranslateType[user?.Role]}
                                                    </p>
                                                )}
                                            </div>

                                            <button type="button" className={styles.user__button} onClick={logOut}>
                                                <img src={logout} alt="log-out" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {!isMap && (
                            <div className={styles.officeLayout__content}>
                                <Outlet />
                            </div>
                        )}
                    </div>
                </div>
            </MenuContext.Provider>
        </>
    )
}

export default PrivateLayout
