/**
 * external libs
 */
import React from 'react'
import { DateRangePicker, Range, RangeKeyDict, DateRange } from 'react-date-range'
import ruLocale from 'date-fns/locale/ru'
/**
 * hooks
 */
import { useLocalisation, useCalendarSize } from './hooks'
/**
 * styles
 */
import styles from './datepicker-range.module.scss'

type Props = {
    handleSelect: (rangesByKey: RangeKeyDict) => void
    dpRange: Range[]
}

const DatepickerRangeCalendar: React.FC<Props> = ({ handleSelect, dpRange }) => {
    const { ruStaticRanges, ruInputRanges } = useLocalisation()
    const calendarSize = useCalendarSize()

    if (calendarSize !== 's') {
        return (
            <DateRangePicker
                locale={ruLocale}
                onChange={handleSelect}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                months={calendarSize === 'l' ? 2 : 1}
                className={`big-calendar ${styles.calendar}`}
                ranges={dpRange}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                staticRanges={ruStaticRanges}
                inputRanges={ruInputRanges}
                dateDisplayFormat="yyyy/MM/dd"
            />
        )
    }

    return (
        <DateRange
            locale={ruLocale}
            editableDateInputs={true}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={dpRange}
            dateDisplayFormat="yyyy/MM/dd"
            className={`big-calendar ${styles.calendar}`}
        />
    )
}

export default DatepickerRangeCalendar
