import React from 'react'
import Appeals from './pages/manage/appeals/Appeals'

export type RouteType = {
    path: string
    exact: boolean
    component: React.FC<any> & { preload?: () => void }
    label?: string | JSX.Element
    parent?: string
    children?: any[]
    title?: string
    description?: string
    seoKeys?: string[]
    subtitle?: string
    isBread?: boolean
    wrapperType?: string
}

export type SelectOptionType = {
    id: number | string
    label: string
}

export enum RolesType {
    individuals = 'individuals',
    companies = 'companies',
    drivers = 'drivers',
    operator = 'operator',
    managers = 'managers',
}

export enum RolesTranslateType {
    individuals = 'Физическое лицо',
    companies = 'Юридическое лицо',
    drivers = 'Водитель',
    operator = 'Оператор',
    managers = 'Администратор',
}

export type AlertContentType = {
    type: 'success' | 'info' | 'error'
    text: string
}

export type AlertType = AlertContentType & {
    id: number
}

export type UserContextType = {
    uName: string | null
    uID: string | null
    Role: keyof typeof RolesType | null
    companyid?: string
}

export type TariffType = {
    id?: string
    price?: number
    serviceId?: string
    serviceName?: string
}

export type PetrolType = {
    id: number
    name: string
    tariff: TariffType
}

export type GlobalContextType = {
    addAlert: ((alert: AlertContentType) => void) | null
    cards: CardType[] | null
    setCards: React.Dispatch<React.SetStateAction<any | null>> | null
    user: UserContextType | null
    changeUser: React.Dispatch<React.SetStateAction<UserContextType | null>> | null
    setLoaderCount: React.Dispatch<React.SetStateAction<number>> | null
    pushCount: number | null
    setPushCount: React.Dispatch<React.SetStateAction<number>> | null
    petrol: any[] | null
    setPetrol: React.Dispatch<React.SetStateAction<any[] | null>> | null
    activeCard: null
    setActiveCard: React.Dispatch<React.SetStateAction<any | null>> | null
    selectedCard: null | any
    setSelectedCard: React.Dispatch<React.SetStateAction<any | null>>
    services: any[]
    setServices: React.Dispatch<React.SetStateAction<any | null>>
    // allDrivers: null
    // setAllDrivers: React.Dispatch<React.SetStateAction<any | null>> | null
}

export type MenuContextType = {
    openChat: boolean
    setOpenChat: React.Dispatch<React.SetStateAction<boolean>>
    openDrivers: boolean
    setOpenDrivers: React.Dispatch<React.SetStateAction<boolean>>
    openFuelCards: boolean
    setOpenFuelCards: React.Dispatch<React.SetStateAction<boolean>>
}

export type cardUserType = {
    id: string
    name: string
    balance: string
    card: {
        number: string
    }
}

export type BalanceType = {
    id: string
    monthlyLimit: number
    dailyLimit: number
    sizePurse: number
    individualLimit: boolean
    idCircuit: number
    descriptionCircuit: string
    serviceId: string
    serviceName: string
    sizeAccount: number
}

export type CardType = {
    dailyLimit?: any
    monthlyLimit?: number
    weeklyLimit?: any
    id: string
    cardNumber: string
    idCondition: number
    descriptionCondition: string
    cardDate: string
    serviceId?: string
}

export type AuthType = {
    login: string
    password: string
}

export type RecoveryType = {
    phone: string
}

export type AreaType = {
    id: string
    cardNumber?: string
    fio?: string
    company?: string
    inn?: string
    role?: keyof typeof RolesType
    companyid?: string
}

export type UserAreasType = {
    [RolesType.companies]?: AreaType[]
    [RolesType.individuals]?: AreaType[]
    [RolesType.drivers]?: AreaType[]
    [RolesType.managers]?: AreaType[]
    [RolesType.operator]?: AreaType[]
}

export type NotificationType = {
    id: string
    message: string
    userId: string
    state: string
    createdAt: string
}

export type ChatMessageRowType = {
    id: string
    text: string
    createdAt?: string
    updatedAt?: string
    userId: string
}

export type ChatMessageType = {
    [key: string]: ChatMessageRowType[]
}

export type OperationType = {
    cardNumber: string
    id: string
    date: string
    sumForWhat: number
    sumThan: number
    operation: number
    serviceForWhatId: string
    serviceForWhatName: string
    serviceThanId: string
    serviceThanName: string
}

export type OperationsType = {
    [key: string]: OperationType[]
}

export type WalletType = {
    id: string
    limits: any
    limitsCosts: any
    sizePurse: number
    individualLimit: boolean
    idCircuit: number
    descriptionCircuit: string
    serviceId: string
    serviceName: string
    sizeAccount?: number
}

export enum FuelType {
    fuel92 = 'АИ-92',
    fuel95 = 'АИ-95',
    fuel95plus = 'АИ-95+',
    fuel100ps = 'АИ-100 ПС',
}

export const FuelColorType = {
    [FuelType.fuel92]: '255, 208, 0',
    [FuelType.fuel95]: '52, 170, 10',
    [FuelType.fuel95plus]: '240, 68, 56',
    [FuelType.fuel100ps]: '43, 111, 243',
}

export type CardDriverType = {
    id: string
    fio: string
    phone: string
    createdAt: string
    addedDateTime?: string
    cardDate?: string
}

export type CardWithDriverType = CardType & { drivers: CardDriverType[] }

export type DriverType = {
    id: string
    fio: string
    phone: string
    createdAt: string
}

export type DriverWithCardsType = DriverType & { cards: CardType[] }
export type DriverWithCardType = DriverType & { card: CardType }

export type DriverFormType = {
    fio: string
    phone: string
    cards?: (CardType & { label: string })[] | null
}

export type GasStationType = {
    id: string
    supplier: string
    address: string
    services: string[]
    coordinates: number[]
    distance: number
    inn: string
    isTrassa: boolean
}

export type GasStationServerType = Omit<GasStationType, 'coordinates'> & { coordinates: string }

export type ServiceType = {
    service: string
    color?: string
}

export type MapGlobalContextType = {
    viewType: number
    setViewType: React.Dispatch<React.SetStateAction<number>>
    filterOpen: boolean
    setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
    gasStations: GasStationType[] | null
    coordinatesFilter: number[]
    fuelFilter: string[]
    setFuelFilter: React.Dispatch<React.SetStateAction<string[]>>
    servicesFilter: string[]
    setServicesFilter: React.Dispatch<React.SetStateAction<string[]>>
    setCoordinatesFilter: React.Dispatch<React.SetStateAction<number[]>>
    ymapsObject: object | null
    setYmapsObject: React.Dispatch<React.SetStateAction<object | null>>
    setActiveItem: React.Dispatch<React.SetStateAction<GasStationType | null>>
    activeItem: GasStationType | null
}

export type UserType = {
    id: string
    fio?: string
    phone?: string
    name?: string
    login?: string
    inn?: string
    kpp?: string
    okpo?: string
    email?: string
    contactPerson?: string
    createdAt?: string
    updatedAt?: string
    date?: string
}

export type UserWithCardsType = UserType & { cards: CardType[] }

export type TableHRType = {
    onClick?: any
    id: string
    keys: string | string[]
    subKeys?: string[]
    label: string
    isFilter: boolean
}

export type TabType = {
    id: number
    label: string
}

export type OperatorEditUserFormType = {
    fio?: string | null
    inn?: string | null
    kpp?: string | null
    email?: string | null
}

export type OperatorEditIndividualFormType = {
    fio?: string | null
    phone?: string | null
}

export type OperatorEditDriverFormType = {
    fio?: string
    phone?: string
    cards?: CardType[]
}

export type OperatorPageContextType = {
    label: string
    subtext?: string
    linkHandler: (() => void) | null
    setLabel: React.Dispatch<React.SetStateAction<string>> | null
    setSubtext?: React.Dispatch<React.SetStateAction<string>> | null
    setLinkHandler: React.Dispatch<React.SetStateAction<(() => void) | null>> | null
    services?: string[]
}

export type ManagerType = {
    id: string
    fio: string
    phone: string
    createdAt?: string
    company: UserType
    login?: string
    email?: string
    password?: string
}

export type OperatorEditManagerFormType = {
    fio?: string
    phone?: string
    login?: string
    email?: string
    password?: string
    confirm?: string
    activate?: boolean
}

export type PaymentsType = {
    id: string
    fio: string
    phone: string
    pdf: string
    createdAt?: string
}

export type AppealsType = {
    userId: string
    userLogin: string
    userFio: string
    updatedAt: string
}

export type AppealsWithUserType = AppealsType & {
    user: UserType & UserAreasType
}

export enum RolePushType {
    ROLE_INDIVIDUAL = 'ROLE_INDIVIDUAL',
    ROLE_COMP_DRIVER = 'ROLE_COMP_DRIVER',
    ROLE_OPERATOR = 'ROLE_OPERATOR',
    ROLE_COMP_MANAGER = 'ROLE_COMP_MANAGER',
}

export type PushType = {
    id: string
    fio: string
    phone: string
    login: string
    createdAt?: string
    roles: (keyof typeof RolePushType)[]
}

export type PushObjectType = {
    id: string
    name: string
    status: string
    createdAt?: string
    userId: string
    message: string
    recipients: (UserType & { role: RolesType })[]
}

export type GasStationFormType = {
    supplier?: string | null
    address?: string | null
    services?:
        | {
              id: string
              label: string
          }[]
        | null
    inn?: string
    activate?: boolean | null
    isTrassa?: boolean
}

export type OperatorFormType = {
    fio?: string
    phone?: string
}

export type TariffsType = {
    id: string
    price: number
    serviceId: string
    serviceName: string
}
