/**
 * external libs
 */
import Select from 'react-dropdown-select'
/**
 * types
 */
import { SelectOptionType } from './../../types'

type Props<T> = {
    options: T[]
    changeHandler: (values: T[]) => void
    values: T[] | any
    multi?: boolean
    placeholder?: string
    searchable?: boolean
    isGray?: boolean
    isError?: boolean
    isOperatorField?: boolean
}

function DropDown<T extends SelectOptionType>(props: Props<T>) {
    const {
        options,
        changeHandler,
        values = [],
        multi = false,
        placeholder = '',
        searchable = false,
        isGray = false,
        isError = false,
        isOperatorField = false,
    } = props

    return (
        <Select
            values={values}
            options={options}
            onChange={changeHandler}
            multi={multi}
            placeholder={placeholder}
            searchable={searchable}
            className={`dropdown ${isGray ? 'dropdown_gray' : ''} ${isError ? 'dropdown_error' : ''} ${isOperatorField ? 'little' : ''}`}
            valueField="id"
            dropdownGap={0.1}
            dropdownPosition="auto"
        />
    )
}

export default DropDown
