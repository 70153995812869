/**
 * external libs
*/
import React from "react";
/**
 * styles
*/
import styles from './input.module.scss';

type Props = {
    type?: "text" | "password";
    placeholder?: string;
    isError?: boolean;
    value: string;
    change: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Input: React.FC<Props> = ({ change, type = "text", placeholder = "", isError = false, value = "" }) => {
    return (
        <input
            type={type}
            className={`${styles.input} ${isError ? styles.input_error : ""}`}
            placeholder={placeholder}
            value={value}
            onChange={change}
        />
    );
};

export default Input; 