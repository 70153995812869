/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const FuelCards = lazy(() => import("./FuelCards"));

const FuelCardsComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <FuelCards {...props} />
        </Suspense>
    );
};

export default FuelCardsComponent;