/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Map = lazy(() => import("./Map"));

const MapComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Map {...props} />
        </Suspense>
    );
};

export default MapComponent;