/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import AllFuelCard from './AllFuelCard'

type Props = {
    setCurrentPage: any
    currentPage: number
}

const FuelCardPortal: React.FC<Props> = ({ setCurrentPage, currentPage }) => {
    return <AllFuelCard setCurrentPage={setCurrentPage} currentPage={currentPage} />
}

export default FuelCardPortal
