import * as yup from "yup";

export const driverSchema = yup.object().shape({
    fio: yup
        .string()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    phone: yup
        .string()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    cards: yup
        .array()
        .nullable()
});