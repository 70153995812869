/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Alerts = lazy(() => import("./Alerts"));

const AlertsComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Alerts {...props} />
        </Suspense>
    );
};

export default AlertsComponent;