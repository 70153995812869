/**
 * external libs
 */
import React, { useEffect, useState } from 'react'
/**
 * components
 */
import CheckIco from './../../assets/icons/checkIco.svg'
/**
 * styles
 */
import styles from './checkbox.module.scss'

type Props = {
    isActive?: boolean
    label?: string
    handler?: Function
}

const CheckBox: React.FC<Props> = ({ isActive, label, handler }) => {
    const [checked, setChecked] = useState(isActive)

    useEffect(() => {
        if (checked !== isActive) {
            setChecked(isActive)
        }
    }, [isActive, checked])

    const checkerHandler = () => {
        if (handler) {
            handler(!isActive)
        } else {
            setChecked((pre) => !pre)
        }
    }

    return (
        <div className={styles.checkbox} onClick={checkerHandler}>
            <div className={`${styles.checkbox__checker} ${checked ? styles.active : ''}`}>
                <img src={CheckIco} alt={label} className={styles.checkbox__ico} />
            </div>

            {!!label && <span className={styles.checkbox__label}>{label}</span>}
        </div>
    )
}

export default CheckBox
