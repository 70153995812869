/**
 * external libs
 */
import React, { useContext, useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
/**
 * components
 */
import Search from './../../assets/icons/search.svg'
import Button from './../../common-components/button/Button'
/**
 * context
 */
import { MapGlobalContext } from './MapContext'
import { DEFAULT_COORDINATES } from './../../layouts/private/hooks'
/**
 * types
 */
import { MapGlobalContextType } from './../../types'
/**
 * styles
 */
import styles from './map.module.scss'

const StanchionChooser: React.FC = () => {
    const navigate = useNavigate()

    const { setViewType, setFilterOpen, ymapsObject, setCoordinatesFilter } =
        useContext<MapGlobalContextType>(MapGlobalContext)
    const submitHandler = useCallback(() => setViewType(1), [])
    const [address, setAddress] = useState('')
    let debouncer: NodeJS.Timeout | undefined = undefined

    const getCoordinate = async () => {
        try {
            //@ts-ignore
            const res = await ymapsObject.geocode(address, {
                results: 1,
            })

            const coordinates = res.geoObjects.get(0).geometry.getCoordinates()
            if (Array.isArray(coordinates) && coordinates[0] >= 0 && coordinates[1] >= 0) {
                setCoordinatesFilter(coordinates)
            }
        } catch (e: any) {
            console.log(e)
        }
    }

    const setSearchHandler = () => {
        if (ymapsObject) {
            clearTimeout(debouncer)
            if (address) {
                debouncer = setTimeout(getCoordinate, 500)
            } else {
                debouncer = setTimeout(() => setCoordinatesFilter(DEFAULT_COORDINATES), 500)
            }
        }
    }

    useEffect(() => {
        setSearchHandler()
    }, [address])

    if (!ymapsObject) {
        return null
    }

    return (
        <div className={styles.stanchionChooser}>
            <div className={styles.stanchionChooser__header}>
                <button
                    type="button"
                    className={styles.stanchionChooser__openButton}
                    onClick={() => navigate('/office')}>
                    <svg
                        className={styles.stanchionChooser__openIco}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.6001 16.8L14.4001 12L9.6001 7.20005"
                            strokeWidth="1.8"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>

                <div className={styles.stanchionChooser__inputWrapper}>
                    <img src={Search} alt="Search" className={styles.stanchionChooser__search} />

                    <input
                        type="text"
                        placeholder="Адрес АЗС"
                        className={styles.stanchionChooser__input}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.stanchionChooser__sortButtonsWrapper}>
                <Button
                    label="Список"
                    type="button"
                    subClasses={styles.stanchionChooser__sortButton}
                    handler={submitHandler}
                />

                <Button
                    label="Фильтры"
                    type="button"
                    subClasses={styles.stanchionChooser__filterButton}
                    handler={() => setFilterOpen((pre) => !pre)}
                />
            </div>
        </div>
    )
}

export default StanchionChooser
