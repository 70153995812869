/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Appeals = lazy(() => import("./Appeals"));

const AppealsComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Appeals {...props} />
        </Suspense>
    );
};

export default AppealsComponent;