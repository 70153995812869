/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Office = lazy(() => import("./Office"));

const OfficeComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Office {...props} />
        </Suspense>
    );
};

export default OfficeComponent;