/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './button.module.scss'

type Props = {
    handler?: () => void
    label: string
    type?: 'button' | 'submit' | 'reset'
    buttonStyle?: 'normal' | 'outline'
    ico?: React.ReactElement<any>
    subClasses?: string
}

const Button: React.FC<Props> = ({ label, handler, ico, type = 'button', buttonStyle = 'normal', subClasses = '' }) => {
    const icoModern = ico
        ? React.cloneElement(ico, {
              className: styles.button__ico,
          })
        : null

    return (
        <button
            className={`${styles.button} ${buttonStyle === 'outline' ? styles.button_outline : ''} ${subClasses}`}
            type={type}
            onClick={handler}>
            {icoModern}
            <span>{label}</span>
        </button>
    )
}

export default Button
