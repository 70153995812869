/**
 * external libs
 */
import { useRef } from 'react'
/**
 * utils
 */
import useSender from './sender'

export const useAllData = () => {
    const { http } = useSender()

    const getAllData: <T>(url: string, query?: string, page?: number, items?: T[]) => Promise<T[]> = async (url, query = "", page = 1, items = []) => {
        const { data } = await http.get(`${url}?page=${page}${query ? "&" : ""}${query || ""}`);
        const updatedItems = [...items, ...(data.data)]

        if(updatedItems.length >= Number(data.count)) {
            return updatedItems;
        }
        
        return getAllData(url, query, page + 1, updatedItems)
    }

    return { getAllData }
}