/**
 * external libs
 */
import React, { useCallback, useEffect, useState, useContext } from 'react'
import Slider from 'react-slick'
/**
 * components
 */
import TopUpAccountPhis from './phis/TopUpAccountPhis'
import TopUpAccountEntity from './entity/TopUpAccountEntity'
import TopUpAccountDriver from './driver/TopUpAccountDriver'
import Portal from './../../common-components/portal/Portal'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * types
 */
import { RolesType, GlobalContextType, CardType, WalletType } from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'

type Props = {
    wallet: any
    setPortalData: React.Dispatch<React.SetStateAction<any>>
}

const MenuCard: React.FC<Props> = ({ setPortalData, wallet }) => {
    const { user, selectedCard } = useContext<GlobalContextType>(GlobalContext)
    // console.log(wallet)
    return (
        <div
            key={selectedCard?.id}
            {...(user?.Role === RolesType.drivers
                ? {
                      onClick: () => {
                          user?.Role !== RolesType.drivers && setPortalData(wallet?.id)
                      },
                      className: `${styles.cart} ${styles.cart_click}`,
                  }
                : { className: styles.cart })}>
            <p className={styles.cart__number}>{selectedCard?.cardNumber || wallet?.cardNumber}</p>

            {(user?.Role === RolesType.companies || user?.Role === RolesType.individuals) && (
                <>
                    <div className={styles.cart__rest}>
                        <p className={styles.cart__label}>Остаток, {wallet.serviceName}</p>
                        <p className={styles.cart__count}>
                            {wallet.sizePurse} {wallet.unitLetter}
                        </p>
                    </div>

                    <button className={styles.cart__button} onClick={() => setPortalData(wallet?.id)} type="button">
                        {user?.Role === RolesType.individuals ? 'Пополнить' : 'Выставить счет'}
                    </button>
                </>
            )}
        </div>
    )
}

export default MenuCard
