/**
 * external libs
 */
import React, { useContext, useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
/**
 * components
 */
import Button from '../../../common-components/button/Button'
import Field from '../../../common-components/field/Field'
import Input from '../../../common-components/input/Input'
import DropDown from '../../../common-components/drop-down/DropDown'
/**
 * context
 */
import { GlobalContext } from './../../../App'
/**
 * utils
 */
import { topUpAccountSchema } from './yup.account'
import useSender from './../../../utils/sender'
/**
 * styles
 */
import styles from './../../layouts.module.scss'
/**
 * types
 */
import { SelectOptionType, GlobalContextType } from './../../../types'

type TopUpAccountFormType = {
    replenishment_amount: number
    fuel_type: number | null
    card: string | null
    // nds_type: number | null;
}

const TopUpAccountPhis: React.FC<{ close: Function }> = ({ close }) => {
    const { addAlert, petrol, cards } = useContext<GlobalContextType>(GlobalContext)
    const { http } = useSender()
    // const [tariffs, setTariffs] = React.useState<SelectOptionType[]>([])

    // const getTariffs = async () => {
    //     try {
    //         const { data } = await http.get('/tariffs')
    //         console.log(data, petrol)
    //         petrol?.forEach((e: any) => {
    //             data?.forEach((item: any) => {
    //                 if(data.serviceName === 'Услуга ЭЗ' && item.serviceName === 'Электрозарядка') {
    //                     petrol.tariff = item
    //                 }
    //             })
    //         })
    //         setTariffs(data)
    //     } catch (e: any) {
    //         if (addAlert) {
    //             addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
    //         }
    //     }
    // }

    // useEffect(() => {
    //     getTariffs()
    // }, [])

    const {
        register,
        handleSubmit,
        control,
        setError,
        formState: { errors },
        watch,
        setValue,
    } = useForm<TopUpAccountFormType>({
        mode: 'onBlur',
        resolver: yupResolver<TopUpAccountFormType>(topUpAccountSchema),
        defaultValues: {
            replenishment_amount: 0,
            fuel_type: null,
            card: null,
        },
    })

    const submitHandler: SubmitHandler<TopUpAccountFormType> = async (formdata) => {
        try {
            const { data } = await http.post('/payments', {
                petrol: formdata.fuel_type,
                cardNumber: formdata.card,
                amount: formdata.replenishment_amount,
            })
            window.open(data.invoiceUrl, '_blank')
            close()
        } catch (e: any) {
            if (addAlert) {
                addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
            }
        }
    }

    const price = petrol?.find((item) => item.paymentId === watch('fuel_type'))?.price || 0

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(submitHandler)} className={styles.form}>
            <div className={styles.form__container}>
                <Controller
                    name="fuel_type"
                    control={control}
                    render={({ field }) => (
                        <Field label="Топливо" error={errors[field.name]?.message}>
                            <DropDown
                                values={field.value ? (petrol || []).filter((option) => option.id === field.value) : []}
                                changeHandler={(vals) => field.onChange(vals?.[0].id || null)}
                                placeholder="Топливо"
                                isGray={true}
                                options={(petrol || []).map((option: any) => ({
                                    id: option.paymentId,
                                    label: option.serviceName,
                                }))}
                            />
                        </Field>
                    )}
                />

                <Controller
                    name="replenishment_amount"
                    control={control}
                    render={({ field }) => (
                        <Field label="Сумма пополнения, руб" error={errors[field.name]?.message}>
                            <Input
                                placeholder="Сумма пополнения"
                                value={String(field.value) || ''}
                                change={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const trimVal: string = (e.target.value || '').trim()
                                    //@ts-ignore
                                    if (!isNaN(trimVal)) {
                                        field.onChange(Number(trimVal))
                                    }
                                }}
                            />
                        </Field>
                    )}
                />

                {petrol?.find((item) => item.paymentId === watch('fuel_type')) ? (
                    <>
                        <Field label="Цена за литр" noError={true}>
                            <div className={styles.form__textWrapper}>
                                <p className={styles.form__text}>
                                    {petrol?.find((item) => item.paymentId === watch('fuel_type')).serviceName} /{' '}
                                    {price}р.
                                </p>
                            </div>
                        </Field>
                        <Field label="Сколько литров" noError={true}>
                            <div className={styles.form__textWrapper}>
                                <p className={styles.form__text}>
                                    <span className={styles.form__text_green}>
                                        + {price ? (watch('replenishment_amount') / price).toFixed(2) : 0} л
                                    </span>
                                </p>
                            </div>
                        </Field>
                    </>
                ) : null}

                <Controller
                    name="card"
                    control={control}
                    render={({ field }) => (
                        <Field label="Карта" error={errors[field.name]?.message}>
                            <DropDown
                                values={field.value ? (cards || []).filter((option) => option.id === field.value) : []}
                                changeHandler={(vals) => field.onChange(vals?.[0].id || null)}
                                placeholder="Выберите карту"
                                isGray={true}
                                options={(cards || []).map((option) => ({
                                    id: option.cardNumber,
                                    label: option.cardNumber,
                                }))}
                            />
                        </Field>
                    )}
                />
            </div>

            <Button label="Пополнить баланс" type="submit" subClasses={styles.form__submit} />
        </form>
    )
}

export default TopUpAccountPhis
