/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Recovery = lazy(() => import("./Recovery"));

const RecoveryComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Recovery {...props} />
        </Suspense>
    );
};

export default RecoveryComponent;