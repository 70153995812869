/**
 * external libs
 */
import React from "react";
/**
 * styles
*/
import styles from './chat.module.scss';

type Props = {
    message: string;
    date: string;
    type: "incoming" | "outcoming";
};

const Item: React.FC<Props> = ({message, date, type}) => {
    return (
        <div className={`${styles.chat__item} ${type === "outcoming" ? styles.chat__item_out : ""}`}>
            <div className={`${styles.chat__part} ${type === "outcoming" ? styles.chat__part_out : ""}`}>
                <p className={styles.chat__message}>{message}</p>
                <p className={styles.chat__date}>{date}</p>
            </div>
        </div>
    );
};

export default Item;