/**
 * external libs
*/
import React from "react";
/**
 * components
*/
import Alert from "./Alert";
/**
 * types
*/
import { AlertType } from "./../../types";
/**
 * styles
*/
import styles from './alerts.module.scss';


type Props = {
    alerts: AlertType[];
    setAlerts: React.Dispatch<React.SetStateAction<AlertType[]>>;
};

const Alerts: React.FC<Props> = ({ alerts, setAlerts }) => {
    return (
        <div className={styles.alerts}>
            {
                alerts.map((alert) => (
                    <Alert
                        key={alert.id}
                        alert={alert}
                        setAlerts={setAlerts}
                    />
                ))
            }
        </div>
    );
};

export default Alerts;