/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const GasStations = lazy(() => import("./GasStations"));

const GasStationsComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <GasStations {...props} />
        </Suspense>
    );
};

export default GasStationsComponent;