/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './pagination.module.scss'

type Props = {
    prevPage: () => void
    paginationArray: number[]
    activePage: number
    setActivePage: (page: number) => void
    nextPage: () => void
}

const Pagination: React.FC<Props> = ({ prevPage, paginationArray, activePage, setActivePage, nextPage }) => {
    const totalPages = paginationArray.length
    const generatePagination = () => {
        if (totalPages <= 5) {
            return paginationArray // Выводим все страницы, если их меньше или равно 5
        }

        if (activePage <= 4) {
            return [1, 2, 3, 4, 5, '...', totalPages] // Если активная страница ≤ 4, выводим первые страницы и последнюю
        }

        if (activePage >= totalPages - 3) {
            return [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages] // Если активная страница ближе к концу
        }

        return [1, '...', activePage - 1, activePage, activePage + 1, '...', totalPages] // Стандартный случай
    }

    return (
        <div className={styles.pagination__paginationWrapper}>
            <svg
                className={styles.pagination__paginationArrow}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={prevPage}>
                <path
                    d="M14.3999 17.2998L9.5999 12.4998L14.3999 7.6998"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>

            <div className={styles.pagination}>
                {generatePagination().map((page, index) => {
                    if (page === '...') {
                        return (
                            <p key={`dots_${index}`} className={styles.pagination__dots}>
                                {page}
                            </p>
                        )
                    }

                    return (
                        <p
                            key={`page_${page}`}
                            className={`${styles.pagination__page} ${page === activePage ? styles.active : ''}`}
                            onClick={() => setActivePage(Number(page))}>
                            {page}
                        </p>
                    )
                })}
            </div>

            <svg
                className={`${styles.pagination__paginationArrow} ${styles.pagination__paginationArrow_next}`}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={nextPage}>
                <path
                    d="M14.3999 17.2998L9.5999 12.4998L14.3999 7.6998"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    )
}

export default Pagination
