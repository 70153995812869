/**
 * external libs
*/
import React, { PropsWithChildren } from "react";
/**
 * styles
*/
import styles from './field.module.scss';

type Props = {
    label?: string;
    error?: string;
    noError?: boolean;
    helper?: string;
};

const Field: React.FC<PropsWithChildren & Props> = ({ children, label, error, helper, noError = false }) => {
    const child = React.Children.only(children) as any;

    const childComponent = noError ? child : React.cloneElement(child, {
        isError: !!error,
    });

    return (
        <div className={styles.field}>
            {
                !!label &&
                <p className={styles.field__label}>{label}</p>
            }

            <div className={styles.field__wrapper}>
                {childComponent}

                {
                    !!error &&
                    <p className={styles.field__error}>{error}</p>
                }

                {
                    !error && !!helper &&
                    <p className={`${styles.field__error} ${styles.field__error_helper}`}>{helper}</p>
                }
            </div>
        </div>
    );
};

export default Field; 