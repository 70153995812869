/**
 * external libs
 */
import React, { useState, useContext, useEffect } from 'react'
/**
 * components
 */
import Driver from '../../../../common-components/driver/Driver'
import Button from '../../../../common-components/button/Button'
/**
 * context
 */
import { GlobalContext } from './../../../../App'
/**
 * utils
 */
import useSender from './../../../../utils/sender'
/**
 * types
 */
import { GlobalContextType, DriverWithCardsType, CardType } from './../../../../types'
/**
 * styles
 */
import styles from './../../../layouts.module.scss'
import { useAllData } from '../../../../utils/all-data'

type Props = {
    driver: DriverWithCardsType
    setActiveDriver: React.Dispatch<React.SetStateAction<DriverWithCardsType | null>>
    setEditCard: React.Dispatch<React.SetStateAction<any | null>>
    setDrivers: React.Dispatch<React.SetStateAction<DriverWithCardsType[] | null>>
}

const ViewDriver: React.FC<Props> = ({ driver, setActiveDriver, setDrivers, setEditCard }) => {
    const { http } = useSender()
    const { addAlert, user } = useContext<GlobalContextType>(GlobalContext)
    const [confirm, setConfirm] = useState(false)
    const [wallets, setWallets] = useState<any | null>(null)
    const { getAllData } = useAllData()
    const deleteDriver = async () => {
        if (user) {
            try {
                await http.delete(`/companies/${user.uID}/drivers/${driver.id}`)
                setDrivers((pre) => (pre || []).filter((driverItem) => driverItem.id !== driver.id))
                setActiveDriver(null)
            } catch (e: any) {
                if (addAlert) {
                    addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
                }
            }
        }
    }

    useEffect(() => {
        const getWallets = async () => {
            if (user) {
                try {
                    // const wallets = getAllData(`/companies/${user.uID}/cards/${driver.cards.id}/wallets`)
                    const wallets: any = await Promise.all(
                        (driver.cards || []).map(async (card) => {
                            const data = await getAllData<any>(`/companies/${user.uID}/cards/${card.id}/wallets`)
                            // data[0].cardNumber = card.cardNumber
                            // console.log(data[0])
                            let wallets = data[0] || []
                            if (wallets.length !== 0) {
                                wallets.cardNumber = card.cardNumber
                            }
                            return wallets
                        })
                    )
                    setWallets(wallets.flat())
                } catch (e: any) {
                    if (addAlert) {
                        addAlert({
                            text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`,
                            type: 'error',
                        })
                    }
                }
            }
        }

        getWallets()
    }, [driver])

    return (
        <div className={styles.drivers__edit}>
            {!!confirm ? (
                <p className={styles.drivers__partTitle}>Вы точно хотите удалить этого водителя?</p>
            ) : (
                <div className={styles.drivers__partWrapper}>
                    <p className={styles.drivers__partTitle}>
                        Кошельки топливных карт:{' '}
                        <span className={styles.drivers__partTitle_blue}>{(wallets || []).length}</span>
                    </p>

                    <div className={styles.drivers__cardsListScroll}>
                        <div className={styles.drivers__cardsListWrapper}>
                            {(wallets || []).map((wallet: any) => (
                                <Driver
                                    key={wallet.id}
                                    driver={{ ...driver }}
                                    wallet={wallet}
                                    handler={() => setEditCard(wallet)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.drivers__buttonWrapper}>
                {!confirm && (
                    <Button
                        label="Назад"
                        type="button"
                        subClasses={styles.drivers__button}
                        handler={() => setActiveDriver(null)}
                    />
                )}

                <Button
                    label="Удалить водителя"
                    type="button"
                    subClasses={`${styles.drivers__button} ${styles.drivers__button_red}`}
                    handler={confirm ? deleteDriver : () => setConfirm(true)}
                />
            </div>
        </div>
    )
}

export default ViewDriver
