export const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

export function getLocalAccessToken() {
    return sessionStorage.getItem('accessToken') ?? localStorage.getItem('accessToken')
}

export function getLocalRefreshToken() {
   return sessionStorage.getItem('refreshToken') ?? localStorage.getItem('refreshToken')
}

export function clearStorage() {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('uid')
    localStorage.removeItem('urole')
    localStorage.removeItem('uname')
}
