/**
 * external libs
 */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
/**
 * styles
 */
import styles from './menu-link.module.scss'

type Props = {
    ico: React.ReactElement<any>
    title: string
    to?: string
    count?: number | null
    fillType?: 'stroke' | 'fill'
    onClickHandler?: React.Dispatch<any>
}

const MenuLink: React.FC<Props> = ({ ico, title, to, count, fillType = 'fill', onClickHandler }) => {
    const location = useLocation()

    const icoModern = React.cloneElement(ico, {
        className: `${styles.menuLink__ico} ${fillType === 'stroke' ? styles.menuLink__ico_stroke : ''}`,
    })

    if (to) {
        return (
            <Link
                to={to}
                className={`${styles.menuLink} ${to === location.pathname ? styles.menuLink_active : ''}`}
                onClick={onClickHandler ? onClickHandler : undefined}>
                <span className={styles.menuLink__wrapper}>
                    {icoModern}

                    <span className={styles.menuLink__title}>{title}</span>
                </span>

                {!!(count || count === 0) && <span className={styles.menuLink__count}>{count}</span>}
            </Link>
        )
    }

    return (
        <div
            className={`${styles.menuLink} ${to === location.pathname ? styles.menuLink_active : ''}`}
            {...(onClickHandler ? { onClick: onClickHandler } : {})}>
            <span className={styles.menuLink__wrapper}>
                {icoModern}

                <span className={styles.menuLink__title}>{title}</span>
            </span>
        </div>
    )
}

export default MenuLink
