/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import arrow from './../../assets/icons/chevron-left.svg'
/**
 * styles
 */
import styles from './fuel-card.module.scss'

type Props = {
    number: string
    owner: string
    clickHandler: () => void
}

const FuelCard: React.FC<Props> = ({ number, owner, clickHandler }) => {
    return (
        <div className={styles.fuelCard} onClick={clickHandler}>
            <div className={styles.fuelCard__data}>
                <div className={styles.fuelCard__numberBG}>
                    <p className={styles.fuelCard__number}>{number}</p>
                </div>

                {owner?.length !== 0 && <p className={styles.fuelCard__name}>{owner}</p>}
            </div>

            <img src={arrow} alt="check card" className={styles.fuelCard__ico} />
        </div>
    )
}

export default FuelCard
