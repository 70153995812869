/**
 * external libs
 */
import React from "react";
import ReactDOM from "react-dom";
/**
 * styles
*/
import styles from './loader.module.scss';

const Loader: React.FC = () => {
    return ReactDOM.createPortal(
        <div className={styles.loader}>
            <span className={styles.loader__spinner}></span>
        </div>,
        document.body as HTMLElement
    );
};

export default Loader;
