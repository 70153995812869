/**
 * external libs
 */
import React, { PropsWithChildren, createContext, useState } from 'react'
/**
 * hooks
 */
import { usePrivateLayoutData } from './../../layouts/private/hooks'
/**
 * types
 */
import { MapGlobalContextType } from './../../types'

export const MapGlobalContext = createContext<MapGlobalContextType>({
    viewType: 0,
    setViewType: () => {},
    filterOpen: false,
    setFilterOpen: () => {},
    gasStations: [],
    coordinatesFilter: [],
    fuelFilter: [],
    setFuelFilter: () => {},
    servicesFilter: [],
    setServicesFilter: () => {},
    setCoordinatesFilter: () => {},
    ymapsObject: null,
    setYmapsObject: () => {},
    setActiveItem: () => {},
    activeItem: null,
})

const MapContext: React.FC<PropsWithChildren> = ({ children }) => {
    const {
        coordinatesFilter,
        fuelFilter,
        setFuelFilter,
        servicesFilter,
        setServicesFilter,
        setCoordinatesFilter,
        gasStations,
        ymapsObject,
        setYmapsObject,
    } = usePrivateLayoutData()
    const [viewType, setViewType] = useState<number>(0)
    const [activeItem, setActiveItem] = useState<any | null>(null)
    const [filterOpen, setFilterOpen] = useState<boolean>(false)

    return (
        <MapGlobalContext.Provider
            value={{
                viewType,
                setViewType,
                filterOpen,
                setFilterOpen,
                coordinatesFilter,
                fuelFilter,
                setFuelFilter,
                servicesFilter,
                setServicesFilter,
                setCoordinatesFilter,
                gasStations,
                ymapsObject,
                setYmapsObject,
                setActiveItem,
                activeItem,
            }}>
            {children}
        </MapGlobalContext.Provider>
    )
}

export default MapContext
