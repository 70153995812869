/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Push = lazy(() => import("./Push"));

const PushComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Push {...props} />
        </Suspense>
    );
};

export default PushComponent;