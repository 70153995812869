/**
 * external libs
*/
import React, { useEffect, useMemo, useState } from "react";
import { defaultStaticRanges, defaultInputRanges } from 'react-date-range';

export const useLocalisation = () => {
    const staticRangesLabels = {
        "Today": "Сегодня",
        "Yesterday": "Вчера",
        "This Week": "Эта неделя",
        "Last Week": "Прошлая неделя",
        "This Month": "Этот месяц",
        "Last Month": "Прошлый месяц"
    };

    const inputRangesLabels = {
        "days up to today": "дней до сегодня",
        "days starting today": "дней после сегодня"
    };

    const localisation = useMemo(() => {
        const translateRange = (dictionary: any) => {
            return (item: any) =>
                dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
        }

        const ruStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
        const ruInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

        return { ruStaticRanges, ruInputRanges };
    }, [staticRangesLabels, inputRangesLabels]);

    return localisation;
};

export const useCalendarSize = () => {
    const getNewSize = () => {
        const ww = window.innerWidth;

        if(ww > 890) {
            return "l";
        }

        if(ww > 590) {
            return "m";
        }

        return "s";
    };

    const [calendarSize, setCalendarSize] = useState<"l" | "m" | "s">(getNewSize());

    useEffect(() => {
        const changeSize = () => {
            setCalendarSize(getNewSize());
        };

        window.addEventListener("resize", changeSize);

        return () => {
            window.removeEventListener("resize", changeSize);
        };
    }, [setCalendarSize]);

    return calendarSize;
};
