/**
 * external libs
 */
import React, { useState, useEffect, useContext, useRef } from 'react'
import moment from 'moment'
/**
 * components
 */
import DateComponent from './Date'
import Item from './Item'
import ChatIco from './../../../assets/icons/chat.svg'
/**
 * context
 */
import { GlobalContext } from './../../../App'
/**
 * utils
 */
import useSender from './../../../utils/sender'
import { parseJwt } from './../../../utils/jwt'
/**
 * types
 */
import { ChatMessageRowType, GlobalContextType, ChatMessageType } from './../../../types'
/**
 * styles
 */
import styles from './chat.module.scss'

export const ChatTitle: React.FC = () => (
    <div className={styles.chat__titleWrapper}>
        <img src={ChatIco} alt="chat" className={styles.chat__ico} />
        <p className={styles.chat__title}>Чат с техподдержкой</p>
    </div>
)

const Chat: React.FC<{ userId?: string }> = ({ userId = null }) => {
    const { http } = useSender()
    const { addAlert, user } = useContext<GlobalContextType>(GlobalContext)
    const token = localStorage.getItem('accessToken')
    const tokenInfo = token ? parseJwt(token) : { userId: '' }

    const [messageText, setMessageText] = useState<string>('')
    const [messages, setMessages] = useState<ChatMessageType | null>(null)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [page, setPage] = useState(2)
    const messagesStore = useRef<ChatMessageRowType[]>([])
    const messagesGetterLock = useRef(false)

    const sendMessage = async () => {
        try {
            const key = moment().format('DD.MM.YYYY')

            await http.post(userId ? `/support/chats/${userId}` : '/support/chat', { text: messageText })

            setMessages((pre) => ({
                ...pre,
                [key]: [
                    {
                        id: String(Date.now()),
                        text: messageText,
                        createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
                        userId: tokenInfo.userId || user?.uID || '',
                    },
                    ...(pre && pre[key] ? pre[key] : []),
                ],
            }))
            setMessageText('')
        } catch (e: any) {
            if (addAlert) {
                addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
            }
        }
    }

    const transformData = (data: ChatMessageRowType[]) =>
        (data || []).reduce((accum: ChatMessageType, item: ChatMessageRowType) => {
            const key = moment(item.createdAt, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY')
            const addingItem = {
                ...item,
                createdAt: item.createdAt ? moment(item.createdAt, 'YYYY-MM-DD hh:mm:ss').format('hh:mm') : '',
                updatedAt: item.updatedAt ? moment(item.updatedAt, 'YYYY-MM-DD hh:mm:ss').format('hh:mm') : '',
            }
            accum[key] = accum[key] ? [...accum[key], addingItem] : [addingItem]
            return accum
        }, {})

    const getNextMessages = async () => {
        if (!messagesGetterLock.current) {
            messagesGetterLock.current = true

            try {
                const { data } = await http.get(
                    userId ? `/support/chats/${userId}?page=${page}` : `/support/chat?page=${page}`
                )

                messagesStore.current = [...messagesStore.current, ...data]
                setHasMore(data.length === 50)
                setPage((pre) => pre + 1)
                setMessages(transformData([...messagesStore.current]))
                messagesGetterLock.current = false
            } catch (e: any) {
                if (addAlert) {
                    addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
                }
            }
        }
    }

    const handleScroll = (e: any) => {
        const target = e.target

        if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1 && hasMore) {
            getNextMessages()
        }
    }

    useEffect(() => {
        const getMessage = async () => {
            try {
                const { data } = await http.get(userId ? `/support/chats/${userId}` : `/support/chat`)
                messagesStore.current = data

                setHasMore(data.length === 50)
                setMessages(transformData(data))
            } catch (e: any) {
                if (addAlert) {
                    addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
                }
            }
        }

        getMessage()
    }, [setMessages, user, userId])

    if (messages === null) {
        return null
    }

    return (
        <div className={`${styles.chat__wrapper} ${userId ? styles.chat__wrapper_manager : ''}`}>
            <div
                className={`${styles.chat__content} ${userId ? styles.chat__content_manager : ''}`}
                onScroll={handleScroll}>
                {Object.keys(messages).map((date) => (
                    <React.Fragment key={date}>
                        {messages[date].map((item) => (
                            <Item
                                key={item.id}
                                message={item.text}
                                date={item.createdAt || item.updatedAt || ''}
                                type={item.userId === (tokenInfo.userId || user?.uID) ? 'outcoming' : 'incoming'}
                            />
                        ))}
                        <DateComponent date={date} />
                    </React.Fragment>
                ))}
            </div>

            <div className={styles.chat__footer}>
                <input
                    className={styles.chat__input}
                    placeholder="Ваше сообщение"
                    value={messageText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessageText(e.target.value)}
                />

                <button
                    type="button"
                    className={`${styles.chat__button} ${!messageText.length ? styles.chat__button_disabled : ''}`}
                    disabled={!messageText.length}
                    onClick={sendMessage}>
                    <svg
                        className={styles.chat__buttonIco}
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.5 15.5L12.5 10.5L7.5 5.5"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default Chat
