/**
 * external libs
 */
import React, { useState, useRef, useCallback } from 'react'
import { Range } from 'react-date-range'
import { Transition } from 'react-transition-group'
/**
 * components
 */
import DatepickerRangeCalendar from './DatepickerRangeCalendar'
import Button from './../button/Button'
/**
 * styles
 */
import styles from './datepicker-range.module.scss'

type Props = {
    ico: React.ReactElement<any>
    handleSelect: (rangesByKey: Range[]) => void
    dpRange: Range[]
    label: string
}

const DatepickerRange: React.FC<Props> = ({ ico, label, handleSelect, dpRange }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [dpRangeLocal, setDPRangeLocal] = useState<Range[]>([...dpRange])
    const dpWrapperRef = useRef(null)

    function getDaysBetweenDates(startDate: any, endDate: any) {
        const start: any = new Date(startDate)
        const end: any = new Date(endDate)
        const timeDifference = end - start
        const daysDifference = timeDifference / (1000 * 3600 * 24)

        return daysDifference + 1
    }

    const icoModern = React.cloneElement(ico, {
        className: styles.calendar__ico,
    })

    const setDPRangeHandler = useCallback(
        (item: Range[]) => {
            const { startDate, endDate }: any = item[0]
            const daysDifference = getDaysBetweenDates(startDate, endDate)
            if (daysDifference > 5) {
                const start = new Date(startDate)
                const end = new Date(endDate)
                if (end > start) {
                    if (end.getMonth() !== start.getMonth()) end.setMonth(start.getMonth())
                    if (end.getFullYear() !== start.getFullYear()) end.setFullYear(start.getFullYear())
                    end.setDate(start.getDate() + 4)
                } else {
                    if (start.getMonth() !== end.getMonth()) start.setMonth(end.getMonth())
                    if (start.getFullYear() !== end.getFullYear()) start.setFullYear(end.getFullYear())
                    start.setDate(end.getDate() - 4)
                }
                setDPRangeLocal([{ startDate: start, endDate: end, key: 'selection' }])
            } else {
                setDPRangeLocal(item)
            }
        },
        [setDPRangeLocal]
    )

    const cancelHandler = useCallback(() => {
        setOpen(false)
        setDPRangeLocal([...dpRange])
    }, [setOpen, setDPRangeLocal, dpRange])

    const saveHandler = useCallback(() => {
        setOpen(false)
        handleSelect(dpRangeLocal)
    }, [setOpen, dpRangeLocal, handleSelect])

    return (
        <div className={styles.calendar__wrapper}>
            <button type="button" className={styles.calendar__button} onClick={() => setOpen(true)}>
                {icoModern}
                <span className={styles.calendar__label}>{label}</span>
            </button>

            <Transition nodeRef={dpWrapperRef} in={open} timeout={250} unmountOnExit={false}>
                {(state: string) => (
                    <div
                        ref={dpWrapperRef}
                        className={`${styles.calendar__form} ${state === 'entering' || state === 'entered' || state === 'exiting' ? styles.calendar__form_vis : ''} ${state === 'entered' ? styles.calendar__form_show : ''}`}>
                        <DatepickerRangeCalendar
                            handleSelect={(item) => setDPRangeHandler([item.selection])}
                            dpRange={dpRangeLocal}
                        />

                        <div className={styles.calendar__footer}>
                            <Button label="Отмена" handler={cancelHandler} buttonStyle="outline" />

                            <Button label="Принять" handler={saveHandler} />
                        </div>
                    </div>
                )}
            </Transition>
        </div>
    )
}

export default DatepickerRange
