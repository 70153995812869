/**
 * external libs
 */
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * styles
 */
import styles from './../../../layouts.module.scss'
import FuelCard from '../../../../common-components/fuel-card/FuelCard'
import { GlobalContext } from '../../../../App'
import { MenuContext } from '../../PrivateLayout'

type Props = {
    currentPage: number
    setCurrentPage: any
}

const AllDrivers: React.FC<Props> = ({ currentPage, setCurrentPage }) => {
    const navigate = useNavigate()
    const { cards, setActiveCard } = useContext<any>(GlobalContext)
    const { setOpenFuelCards } = useContext<any>(MenuContext)
    return (
        <div>
            <div className={styles.drivers}>
                <div className={styles.drivers__list}>
                    {(cards || [])?.map((card: any) => (
                        <FuelCard
                            key={card.id}
                            number={card?.cardNumber}
                            owner={card?.drivers?.map((driver: any) => driver?.fio || '').join(', ')}
                            clickHandler={() => {
                                setActiveCard(card)
                                navigate(`/fuel-cards/${card.id}`)
                                setOpenFuelCards(false)
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AllDrivers
