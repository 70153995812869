/**
 * external libs
 */
import React from "react";
/**
 * styles
*/
import styles from './chat.module.scss';

const Date: React.FC<{date: string}> = ({date}) => {
    return (
        <p className={styles.chat__dateTime}>{date}</p>
    );
};

export default Date;