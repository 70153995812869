/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import Driver from '../../../../common-components/driver/Driver'
/**
 * types
 */
import { DriverWithCardsType } from './../../../../types'
/**
 * styles
 */
import styles from './../../../layouts.module.scss'

type Props = {
    drivers: DriverWithCardsType[]
    setActiveDriver: React.Dispatch<React.SetStateAction<DriverWithCardsType | null>>
    setEdit: React.Dispatch<React.SetStateAction<boolean>>
}

const AllDrivers: React.FC<Props> = ({ drivers, setActiveDriver, setEdit }) => {
    return (
        <div className={styles.drivers}>
            <button type="button" onClick={() => setEdit(true)} className={styles.drivers__addDriver}>
                <span className={styles.drivers__addDriverText}>Добавить</span>

                <svg
                    className={styles.drivers__addDriverIco}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5V19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 12H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>

            <div className={styles.drivers__list}>
                {(drivers || []).map((driver) => (
                    <Driver key={driver.id} driver={driver} handler={() => setActiveDriver(driver)} />
                ))}
            </div>
        </div>
    )
}

export default AllDrivers
