/**
 * external libs
 */
import React, { useContext } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
/**
 * components
 */
import Button from './../../../../common-components/button/Button'
import Field from './../../../../common-components/field-public-form/Field'
import Input from './../../../../common-components/input-public-form/Input'
import PhoneInput from './../../../../common-components/input-public-form/PhoneInput'
import DropDown from './../../../../common-components/drop-down/DropDown'
/**
 * context
 */
import { GlobalContext } from './../../../../App'
/**
 * utils
 */
import useSender from './../../../../utils/sender'
import { driverSchema } from './yup.driver'
/**
 * types
 */
import { DriverWithCardsType, GlobalContextType, CardType, DriverFormType, DriverType } from './../../../../types'
/**
 * styles
 */
import styles from './../../../layouts.module.scss'

type Props = {
    driver: DriverWithCardsType | null
    setEdit: React.Dispatch<React.SetStateAction<boolean>>
    setDrivers: React.Dispatch<React.SetStateAction<DriverWithCardsType[] | null>>
}

const EditDriver: React.FC<Props> = ({ driver, setDrivers, setEdit }) => {
    const { addAlert, user, cards } = useContext<GlobalContextType>(GlobalContext)
    const { http } = useSender()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<DriverFormType>({
        mode: 'onBlur',
        defaultValues: {
            fio: '',
            phone: '',
            cards: (driver?.cards || []).map((card) => ({ ...card, label: card.cardNumber })),
        },
        resolver: yupResolver<DriverFormType>(driverSchema),
    })

    const submitHandler: SubmitHandler<DriverFormType> = async (formdata) => {
        if (user?.uID) {
            try {
                const { data } = await http.post(`/companies/${user.uID}/drivers`, {
                    fio: formdata.fio,
                    phone: formdata.phone,
                })

                if ((data as DriverType).id) {
                    if (!driver) {
                        await Promise.all(
                            (formdata.cards || []).map((card: CardType & { label: string }) =>
                                http.post(`/companies/${user.uID}/cards/${card.id}/drivers/${data.id}`, {
                                    company: user.uID,
                                    driver: (data as DriverType).id,
                                    card: card.id,
                                })
                            )
                        )
                    } else {
                        const oldCards: string[] = (driver.cards || []).map((card: CardType) => card.id)
                        const newCards: string[] = (formdata.cards || []).map((card: CardType) => card.id)
                        const newCard = (formdata.cards || []).filter((card) => !oldCards.includes(card.id))
                        const outdateCard = (driver.cards || []).filter((card) => !newCards.includes(card.id))

                        await Promise.all(
                            newCard.map((card: CardType & { label: string }) =>
                                http.post(`/companies/${user.uID}/cards/${card.id}/drivers/${driver.id}`, {
                                    company: user.uID,
                                    driver: (data as DriverType).id,
                                    card: card.id,
                                })
                            )
                        )

                        await Promise.all(
                            outdateCard.map((card: CardType) =>
                                http.delete(`/companies/${user.uID}/cards/${card.id}/drivers/${driver.id}`)
                            )
                        )
                    }

                    setDrivers((pre) => {
                        const modernDriver: DriverWithCardsType = {
                            ...data,
                            cards: (formdata.cards || []).map((card: CardType & { label: string }) => ({
                                id: card.id,
                                cardNumber: card.cardNumber,
                                idCondition: card.idCondition,
                                descriptionCondition: card.descriptionCondition,
                                cardDate: card.cardDate,
                            })),
                        }

                        if (driver) {
                            return (pre || []).map((driver: DriverWithCardsType) => {
                                if (driver.id !== data.id) {
                                    return driver
                                }

                                return modernDriver
                            })
                        } else {
                            return [modernDriver, ...(pre || [])]
                        }
                    })
                    setEdit(false)
                }
            } catch (e: any) {
                if (addAlert) {
                    addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
                }
            }
        }
    }

    if (!(cards || driver)) {
        return null
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(submitHandler)} className={styles.drivers__edit}>
            <div className={styles.drivers__cardsListScroll}>
                <div className={styles.drivers__fieldListWrapper}>
                    <Controller
                        name="fio"
                        control={control}
                        render={({ field }) => (
                            <Field label="Водитель" error={errors[field.name]?.message}>
                                <Input
                                    placeholder="Введите Ваше ФИО"
                                    value={String(field.value) || ''}
                                    change={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        field.onChange(e.target.value || '')
                                    }
                                />
                            </Field>
                        )}
                    />

                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                            <Field label="Телефон" error={errors[field.name]?.message}>
                                <PhoneInput
                                    placeholder="+7 ()"
                                    value={String(field.value) || ''}
                                    change={(value: string) => field.onChange(value || '')}
                                />
                            </Field>
                        )}
                    />

                    <Controller
                        name="cards"
                        control={control}
                        render={({ field }) => (
                            <Field label="Топливная карта" error={errors[field.name]?.message}>
                                <DropDown<CardType & { label: string }>
                                    values={field.value || []}
                                    changeHandler={(vals) => field.onChange(vals)}
                                    placeholder="Выбрать карту"
                                    isGray={true}
                                    multi={true}
                                    options={(driver?.cards || cards || []).map((card) => ({
                                        ...card,
                                        label: card.cardNumber,
                                    }))}
                                />
                            </Field>
                        )}
                    />
                </div>
            </div>

            <div className={styles.drivers__buttonsBlock}>
                <Button label="Сохранить" type="submit" subClasses={styles.drivers__button} />

                {!!driver && (
                    <Button
                        label="Удалить водителя"
                        type="button"
                        subClasses={`${styles.drivers__button} ${styles.drivers__button_red}`}
                        handler={() => {}}
                    />
                )}
            </div>
        </form>
    )
}

export default EditDriver
