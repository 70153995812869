/**
 * components
 */
import Home from '../pages/home/HomeLazy'
import Office from '../pages/office/OfficeLazy'
import Recovery from '../pages/recovery/RecoveryLazy'
import Alerts from '../pages/alerts/AlertsLazy'
import Profile from '../pages/profile/ProfileLazy'
import FuelCards from '../pages/fuel-cards/FuelCardsLazy'
import Map from '../pages/map/MapLazy'
import FuelCardsSingle from '../pages/fuel-cards-Single/FuelCardsSingleLazy'
import ManageCompanies from '../pages/manage/companies/CompaniesLazy'
import ManageIndividuals from '../pages/manage/individuals/IndividualsLazy'
import ManageAppeals from '../pages/manage/appeals/AppealsLazy'
import ManagePush from '../pages/manage/push/PushLazy'
import ManageGasStations from '../pages/manage/gas-stations/GasStationsLazy'
import ManageAdmin from '../pages/manage/admin/AdminLazy'
/**
 * types
 */
import { RouteType } from '../types'

export const publicRoutes: RouteType[] = [
    { path: '/', exact: true, component: Home, title: 'Home', description: 'Home' },
    { path: '/recovery', exact: true, component: Recovery, title: 'Recovery', description: 'Recovery' },
]

export const privateRoutes: RouteType[] = [
    { path: '/office', exact: true, component: Office, title: 'Личный кабинет ГК Трасса', description: 'Office' },
    { path: '/alerts', exact: true, component: Alerts, title: 'Личный кабинет ГК Трасса', description: 'Alerts' },
    { path: '/appeals', exact: true, component: Alerts, title: 'Личный кабинет ГК Трасса', description: 'Appeals' },
    { path: '/profile', exact: true, component: Profile, title: 'Личный кабинет ГК Трасса', description: 'Profile' },
    {
        path: '/fuel-cards',
        exact: true,
        component: FuelCards,
        title: 'Личный кабинет ГК Трасса',
        description: 'Fuel Cards',
    },
    { path: '/map', exact: true, component: Map, title: 'Личный кабинет ГК Трасса', description: 'Map' },
    {
        path: '/fuel-cards/:id',
        exact: true,
        component: FuelCardsSingle,
        title: 'Личный кабинет ГК Трасса',
        description: 'Fuel Cards',
    },
]

export const manageRoutes: RouteType[] = [
    {
        path: '',
        exact: true,
        component: ManageCompanies,
        title: 'Личный кабинет ГК Трасса',
        description: 'Manage companies',
    },
    {
        path: 'individuals',
        exact: true,
        component: ManageIndividuals,
        title: 'Личный кабинет ГК Трасса',
        description: 'Manage individual',
    },
    {
        path: 'appeals',
        exact: true,
        component: ManageAppeals,
        title: 'Личный кабинет ГК Трасса',
        description: 'Manage Appeals',
    },
    {
        path: 'push',
        exact: true,
        component: ManagePush,
        title: 'Личный кабинет ГК Трасса',
        description: 'Manage Push',
    },
    {
        path: 'gas-stations',
        exact: true,
        component: ManageGasStations,
        title: 'Личный кабинет ГК Трасса',
        description: 'Manage gas stations',
    },
    { path: 'admin', exact: true, component: ManageAdmin, title: 'Личный кабинет ГК Трасса', description: 'Admin' },
]
