/**
 * external libs
*/
import React from "react";
import { Link } from "react-router-dom";
/**
 * styles
*/
import styles from './text.module.scss';

type Props = {
    text: string;
    type: "h1" | "h2" | "p";
};

const HTitle: React.FC<Props> = ({text, type}) => {
    switch (type) {
        case "h1": {
            return (
                <h1 className={styles.h1}>{text}</h1>
            );
        }
        case "h2": {
            return (
                <h2 className={styles.h2}>{text}</h2>
            );
        }
        case "p":
        default: {
            return (
                <p className={styles.p}>{text}</p>
            );
        }
    }
};

export default HTitle;